@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@font-face {
  font-family: "Times New Roman";
  src: url(./LiberationSerif-Regular.ttf) format("truetype");
}

nav {
  position: sticky;
  top: 0rem;
  left: 0rem;
  z-index: 2;
}

a {
  text-decoration: none;
  color: #222222;
  &:hover {
    color: #222222;
  }
}

.nssLogo {
  max-width: 90%;
  height: auto;
  display: inline-block;
  border-left: 0.15rem solid black;
}

.bdcLogo {
  width: 5.25rem;
  height: 5.25rem;
}
.navBarTop {
  background-color: #ffffff;
}

.top_link {
  display: inline-block;
  padding: 0.5rem;
  margin: 0.5rem;
  text-decoration: none;
  font-size: 0.95rem;
  color: #333333;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 0.2;
  &:hover {
    color: #1f1f1f;
    text-decoration: underline;
  }
}

.active_top_link {
  font-weight: bold;
  // border-bottom: 3px solid black;
  // box-shadow: 0px 24px 3px -24px black;
}

.logo {
  font-family: "Times New Roman";

  .nss {
    font-size: 3rem;
  }
  .bits {
    font-size: 1rem;
    line-height: 125%;
  }
}

@media all and (min-width: 992px) {
  .brand {
    margin-left: 2rem;
  }

  .links_top {
    margin-right: 2rem;
  }
}
@media all and (max-width: 600px) {
  .top_link:hover {
    text-decoration: none;
  }
}
@media all and (max-width: 600px) {
  .logo {
    width: 90%;
    height: auto;

    .nssLogo {
      display: none;
    }

    .logoName {
      display: none !important;
    }

    .bdcLogo {
      width: 4rem;
      height: 4rem;
    }

    .nss {
      font-size: 2.4rem;
    }
    .bits {
      font-size: 0.8rem;
    }
  }
}
