* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  .loaderWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: floralwhite;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;

    .logo {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      justify-items: center;
      align-items: center;
      width: 16rem;
      height: 16rem;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 5px 50px rgba(0, 0, 0, 0.5);
      margin: 1rem;

      .logoComponent {
        grid-area: 1/1/2/2;
        z-index: 1;
      }
      .ringReveal {
        grid-area: 1/1/2/2;
        z-index: -1;
      }

      img {
        grid-area: 1/1/2/2;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -12.5rem;
        width: 5rem;
        height: 100%;
        background: rgba(255, 250, 240, 0.7);
        transform: skewX(-30deg);
        z-index: 2;
        animation: shine 2s 2s forwards;
      }
    }
    .textAnim {
      display: grid;
      h2 {
        grid-area: 1/1/2/2;
        text-transform: uppercase;
        text-align: center;
        font-size: 2rem;
      }
      h2:nth-child(1) {
        color: transparent;
        -webkit-text-stroke: 2px hsl(0, 100%, 18%);
      }
      h2:nth-child(2) {
        color: hsl(0, 100%, 18%);
        animation: waterFill 1s ease-in-out 2s infinite alternate;
      }
    }
  }
}
@keyframes shine {
  100% {
    left: 25rem;
  }
}
@keyframes waterFill {
  0% {
    clip-path: polygon(
      0 43%,
      4% 33%,
      11% 24%,
      21% 20%,
      33% 19%,
      43% 26%,
      48% 36%,
      53% 46%,
      58% 57%,
      67% 62%,
      78% 62%,
      86% 58%,
      94% 50%,
      100% 39%,
      100% 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      0 35%,
      5% 44%,
      10% 51%,
      20% 57%,
      30% 58%,
      40% 53%,
      49% 45%,
      53% 35%,
      59% 27%,
      69% 21%,
      79% 18%,
      89% 24%,
      96% 32%,
      100% 43%,
      100% 100%,
      0% 100%
    );
  }
}
