.main-hero {
  min-height: 80vh;
  background-color: hsl(248, 100%, 11%);
  color: floralwhite;
  font-family: poppins;
  display: flex;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./bg-droplets-blue.png");

  h1 {
    font-size: 2.4rem;
    line-height: 150%;
  }
}

.time-card {
  background-color: rgb(245, 245, 245);
  box-shadow: 5px 5px 16px 0px #b8b6b6;
  font-family: poppins;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  

  .time-block {
    display: inline-block;
    margin: 1rem;
  }
}
.venue-card {
  --bs-gutter-x:0 !important;
  width: 100%;
  transform: translateY(-30%);
}

.card1 {
  background-color: #edf3f9;
  color: #022649;
}
.card2 {
  background: url("./bg-droplets-red-small.png");
  color: white;
}

@media all and (max-width: 768px) {
  .main-hero {
    h1 {
      font-size: 1.8rem;
      line-height: 125%;
    }
  }
  .time-card {
    text-align: center;
    box-shadow: none;
  }
  .venue-card {
    transform: translateY(0%);
  }
}
