.slideshow-container {
  font-family: poppins;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 3rem;
  background-color: rgb(245, 245, 245);
}

@media (max-width: 1000px) {
  .slideshow-container {
    padding-bottom: 0;
  }
}
@import "~react-image-gallery/styles/scss/image-gallery.scss";
