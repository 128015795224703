.contactBody {
  background-color: rgb(255, 255, 255);
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  h1 {
    font-weight: 500;
  }
  h3 {
    font-weight: 500;
  }
}
.contactForm {
  form {
    .inputHead {
      font-weight: bold;
      font-size: 1.3rem;
      line-height: 2rem;

      color: #000000;
    }
    input {
      font-size: 1.4375rem;
      line-height: 2.125rem;

      color: #828282;
      border: none;
      border-bottom: 1px solid #828282;
      width: 35rem;
      max-width: 100%;
      background-color: rgb(255, 255, 255);
    }
    input:focus,
    textarea:focus {
      outline: none;
    }
    button {
      max-width: 14rem;
      min-height: 4rem;
      background: hsl(248, 100, 12);
      border-radius: 1rem;

      font-size: 1.2rem;
      line-height: 2rem;
      /* identical to box height */

      color: #ffffff;
    }
    textarea {
      width: 35rem;
      max-width: 100%;
      font-size: 1.4375rem;
      line-height: 2.125rem;

      color: #828282;
      border: none;
      border: 1px solid #828282;
      padding: 1rem;
      border-radius: 1rem;
      background-color: rgb(255, 255, 255);
    }
  }
}
@media all and (max-width: 1200px) {
  .contactForm {
    .inputHead {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2.25rem;

      color: #000000;
    }
    input {
      font-size: 1.4375rem;
      line-height: 2.125rem;

      color: #828282;
      border: none;
      border-bottom: 1px solid #828282;
    }

    [type="submit"] {
      height: 4rem;
      width: 13rem;
      background: #333333;
      border-radius: 1.25rem;

      font-size: 1.4rem;

      color: #ffffff;
    }
  }
}
@media all and (max-width: 768px) {
  .contactBody {
    .contactForm {
      form {
        input,
        textarea {
          font-size: 1.1rem;
        }
        textarea {
          padding: 0.75rem;
        }
      }
    }
  }
}
